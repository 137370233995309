@use 'stylesheets/setup' as *;

.subpage-header.error-header {
  padding-bottom: 12rem;
  overflow: hidden;

  @include slice-from(md) {
    padding-bottom: 2rem;
  }

  h1 {
    margin-top: 2rem;
    color: palette(red, bright);
    font-size: 3rem;
  }

  h2 {
    margin: 0;
  }

  .error-divider {
    position: absolute;
    bottom: -1rem;
    left: -2rem;
    width: calc(100% + 4rem);
    font-size: 0;

    @include slice-from(xs) {
      left: calc(50% - 16rem);
      width: 32rem;
    }

    @include slice-from(sm) {
      left: calc(50% - 8rem);
      width: 32rem;
    }

    @include slice-from(md) {
      left: calc(50% + 4rem);
      width: 24rem;
    }

    @include slice-from(lg) {
      left: calc(50% + 4rem);
      width: 32rem;
    }

    @include slice-from(xl) {
      left: calc(50% + 4rem);
      width: 36rem;
    }
  }
}

.subpage-content.error-content {
  .agent-winter-container {
    text-align: right;

    @include slice-from(md) {
      text-align: left;
    }
  }

  .agent-winter {
    margin-top: -12rem;
    max-width: 24rem;
    width: 100%;
  }
}
